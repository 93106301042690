import * as React from "react";
import { Link } from "gatsby";

// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";
import { graphql } from "gatsby";

export default function Maneater({ data }) {
  return (
    <Layout>
      <Helmet title="Maneater | Hall & Oates" />

      <h1>Maneater</h1>

      <h2>Hall & Oates</h2>

      <h4>Key: F#m</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Img fluid={data.file.childImageSharp.fluid} />

      <Columns columns="2">
        <Verse>
          <p>She'll only come out at night</p>
          <p>The lean and hungry type</p>
          <p>Nothing is new, I've seen her here before</p>
          <p>Watching and waiting</p>
          <p>Ooh, she's sitting with you but her eyes are on the door</p>
        </Verse>
        <Verse>
          <p>So many have paid to see</p>
          <p>What you think you're getting for free</p>
          <p>The woman is wild, a she-cat tamed by the purr of a Jaguar</p>
          <p>Money's the matter</p>
          <p>If you're in it for love</p>
          <p>You ain't gonna get too far</p>
        </Verse>
        <Chorus>
          <p>(Oh here she comes)</p>
          <p>Watch out boy she'll chew you up</p>
          <p>(Oh here she comes)</p>
          <p>She's a maneater</p>
        </Chorus>
        <Chorus>
          <p>(Oh here she comes)</p>
          <p>Watch out boy she'll chew you up</p>
          <p>(Oh here she comes)</p>
          <p>She's a maneater</p>
        </Chorus>
        <Verse>
          <p>I wouldn't if I were you</p>
          <p>I know what she can do</p>
          <p>She's deadly man, she could really rip your world apart</p>
          <p>Mind over matter</p>
          <p>Ooh, the beauty is there but a beast is in the heart</p>
        </Verse>
        <Chorus>
          <p>(Oh here she comes)</p>
          <p>Watch out boy she'll chew you up</p>
          <p>(Oh here she comes)</p>
          <p>She's a maneater</p>
        </Chorus>
        <Chorus>
          <p>(Oh here she comes)</p>
          <p>Watch out boy she'll chew you up</p>
          <p>(Oh here she comes)</p>
          <p>She's a maneater</p>
        </Chorus>
        <Chorus>
          <p>(Oh here she comes)</p>
          <p>Watch out boy she'll chew you up</p>
          <p>(Oh here she comes)</p>
          <p>She's a maneater</p>
        </Chorus>
        <Chorus>
          <p>(Oh here she comes)</p>
          <p>Watch out boy she'll chew you up</p>
          <p>(Oh here she comes)</p>
          <p>She's a maneater</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}

export const query = graphql`
  query {
    file(relativePath: { eq: "maneater.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;